<template>
    <div>
        <headers />

        <div class="container mb-20">
            <el-card>
                <div slot="header" class="flex align-items space-between">
                    <div>
                        <el-button :type="type === 'parameter' ? 'danger' : ''"
                            @click="type = 'parameter'">参数对比</el-button>
                        <el-button :type="type === 'appearance' ? 'danger' : ''"
                            @click="type = 'appearance'">外观对比</el-button>
                    </div>
                    <div>
                        <el-checkbox v-model="showUnique" :disabled="prods.length < 2">隐藏相同项</el-checkbox>
                    </div>
                </div>

                <template v-if="type === 'parameter'">
                    <div class="main flex">
                        <div class="left">
                            <div class="item">
                                <div class="inner">产品信息</div>
                            </div>
                            <div class="item" v-show="!showUnique || !areAllProductsSame('price')">
                                <div class="inner">产品价格</div>
                            </div>
                            <div class="item" v-show="!showUnique || !areAllProductsSame('oldprice')">
                                <div class="inner">历年中标价</div>
                            </div>
                            <div class="item" v-show="!showUnique || !areAllProductsSame('size')">
                                <div class="inner">产品尺寸</div>
                            </div>
                            <div class="item" v-show="!showUnique || !areAllProductsSame('weight')">
                                <div class="inner">整机重量</div>
                            </div>
                            <div class="item" v-show="!showUnique || !areAllProductsSame('frequency')">
                                <div class="inner">接收频率</div>
                            </div>
                            <div class="item" v-show="!showUnique || !areAllProductsSame('channel')">
                                <div class="inner">通道数目</div>
                            </div>
                            <div class="item" v-show="!showUnique || !areAllProductsSame('accuracy')">
                                <div class="inner">定位精度</div>
                            </div>
                            <div class="item" v-show="!showUnique || !areAllProductsSame('time')">
                                <div class="inner">首次定位时间</div>
                            </div>
                            <div class="item">
                                <div class="inner">捕获灵敏度</div>
                            </div>
                            <div class="item">
                                <div class="inner">电池容量</div>
                            </div>
                            <div class="item">
                                <div class="inner">电池年限</div>
                            </div>
                            <div class="item">
                                <div class="inner">安装方式</div>
                            </div>
                            <div class="item">
                                <div class="inner">工作温度</div>
                            </div>
                        </div>
                        <div class="right">
                            <!-- 第一行 显示产品图片与标题 -->
                            <div class="item flex">
                                <div class="inner" v-for="(item, index) in prods" :key="item.id">
                                    <i class="el-icon-close" @click="deleteComparison(item.id)"></i>
                                    <div class="image">
                                        <el-image :src="item.img" lazy></el-image>
                                        <p class="text-hide-2">{{ item.name }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="item flex" v-show="!showUnique || !areAllProductsSame('price')">
                                <div class="inner" v-for="(item, index) in prods" :key="item.id">￥{{ item.price }}
                                </div>
                            </div>
                            <div class="item flex" v-show="!showUnique || !areAllProductsSame('oldprice')">
                                <div class="inner" v-for="(item, index) in prods" :key="item.id">{{ item.oldprice }}
                                </div>
                            </div>
                            <div class="item flex" v-show="!showUnique || !areAllProductsSame('size')">
                                <div class="inner" v-for="(item, index) in prods" :key="item.id">{{ item.size }}
                                </div>
                            </div>
                            <div class="item flex" v-show="!showUnique || !areAllProductsSame('weight')">
                                <div class="inner" v-for="(item, index) in prods" :key="item.id">{{ item.weight }}
                                </div>
                            </div>
                            <div class="item flex" v-show="!showUnique || !areAllProductsSame('frequency')">
                                <div class="inner" v-for="(item, index) in prods" :key="item.id">{{ item.frequency }}
                                </div>
                            </div>
                            <div class="item flex" v-show="!showUnique || !areAllProductsSame('channel')">
                                <div class="inner" v-for="(item, index) in prods" :key="item.id">{{ item.channel }}
                                </div>
                            </div>
                            <div class="item flex" v-show="!showUnique || !areAllProductsSame('accuracy')">
                                <div class="inner" v-for="(item, index) in prods" :key="item.id">{{ item.accuracy }}
                                </div>
                            </div>
                            <div class="item flex" v-show="!showUnique || !areAllProductsSame('time')">
                                <div class="inner" v-for="(item, index) in prods" :key="item.id">{{ item['time'] }}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>


                <template v-if="type === 'appearance'">
                    <div class="main flex">
                        <div class="left">
                            <div class="item">
                                <div class="inner">产品信息</div>
                            </div>
                            <div class="item flex align-items justify-content" style="height: 76.2%;">
                                <div class="inner">产品图片</div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="item flex">
                                <div class="inner">
                                    <div class="image">
                                        <i class="el-icon-close" @click="deleteComparison(index)"></i>
                                        <el-image src="https://picsum.photos/140?1" lazy></el-image>
                                        <p class="text-hide-2">北斗信号弹TD10（北斗三号
                                            短报文应急示位搜救终端）</p>
                                    </div>
                                </div>
                                <div class="inner">
                                    <div class="image">
                                        <i class="el-icon-close" @click="deleteComparison(index)"></i>
                                        <el-image src="https://picsum.photos/140?2" lazy></el-image>
                                        <p class="text-hide-2">北斗信号弹TD10（北斗三号
                                            短报文应急示位搜救终端）</p>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="inner">
                                        暂未选择
                                    </div>
                                    <div class="inner">暂未选择</div>
                                    <div class="inner">暂未选择</div>
                                </div>
                            </div>

                            <div class="item flex">
                                <div class="inner images">
                                    <div class="image">
                                        <el-image src="https://picsum.photos/240?2" lazy></el-image>
                                    </div>
                                    <div class="image">
                                        <el-image src="https://picsum.photos/240?3" lazy></el-image>
                                    </div>
                                    <div class="image">
                                        <el-image src="https://picsum.photos/240?4" lazy></el-image>
                                    </div>
                                </div>
                                <div class="inner images">
                                    <div class="image">
                                        <el-image src="https://picsum.photos/240?5" lazy></el-image>
                                    </div>
                                    <div class="image">
                                        <el-image src="https://picsum.photos/240?6" lazy></el-image>
                                    </div>
                                    <div class="image">
                                        <el-image src="https://picsum.photos/240?7" lazy></el-image>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="inner images">
                                        <div class="image">-</div>
                                        <div class="image">-</div>
                                        <div class="image">-</div>
                                    </div>
                                    <div class="inner images">
                                        <div class="image">-</div>
                                        <div class="image">-</div>
                                        <div class="image">-</div>
                                    </div>
                                    <div class="inner images">
                                        <div class="image">-</div>
                                        <div class="image">-</div>
                                        <div class="image">-</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </el-card>
        </div>
        <footers />
    </div>
</template>

<script>
import headers from "@/components/header.vue"
import footers from "@/components/footer.vue"

import { mapState, mapMutations } from "vuex"
export default {
    name: "comparisonIndex",
    components: {
        headers,
        footers,
    },
    data () {
        return {
            type: 'parameter',// 外观:parameter，外观：appearance
            // 隐藏相同项
            showUnique: false,
        }
    },
    computed: {
        ...mapState({
            prods: state => state.comparison.prods,
        }),
    },
    methods: {
        ...mapMutations('comparison', ['deleteComparison']), // 数组写法 删除对比数据
        // 判断所有产品在指定参数上是否相同
        areAllProductsSame (fieldName) {
            if (!this.prods.length) return false // 如果数组为空，则直接返回false
            // 获取第一个产品的字段值作为参考
            const referenceValue = this.prods[0][fieldName]
            // 判断所有产品的字段是否与参考值相同
            const areFieldsTheSame = this.prods.every((product) => product[fieldName] === referenceValue)

            if (this.prods.length > 1) {
                return areFieldsTheSame
            } else {
                return null
            }

        },
    }
}
</script>
<style lang='less' scoped>
.main {
    .left {
        background: #fcfcfc;
        border-left: 1px solid #eee;
        border-top: 1px solid #eee;
        width: 232px;

        .item {
            border-bottom: 1px solid #eee;
            border-right: 1px solid #eee;
            color: #111;
            font-size: 14px;
            height: 50px;
            line-height: 50px;
            text-align: center;

            &:first-child {
                height: 216px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .right {
        border-top: 1px solid #eee;

        .item {
            &:hover {
                background: rgba(255, 120, 52, .1);
            }

            &:first-child {
                .inner {
                    height: 216px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .inner {
                position: relative;
                border-bottom: 1px solid #eee;
                border-right: 1px solid #eee;
                color: #111;
                font-size: 14px;
                min-height: 50px;
                line-height: 50px;
                text-align: center;
                width: 232px;
                overflow: hidden;

                .el-icon-close {
                    position: absolute;
                    top: 5px;
                    right: -100%;
                    font-size: 20px;
                    cursor: pointer;
                    transition: all .3s ease;

                    &:hover {
                        color: #EF312A;
                    }
                }

                &:hover {
                    .el-icon-close {
                        right: 5px;
                    }
                }

                &.images {
                    .image {
                        height: 202px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:not(:last-child) {
                            border-bottom: 1px solid #eee;
                        }
                    }
                }

                .image {
                    line-height: 20px;
                    padding: 15px;

                    .el-image {
                        border-radius: 4px;
                    }

                    p {
                        font-size: 14px;
                        color: #2E3033;
                    }
                }
            }
        }
    }
}
</style>